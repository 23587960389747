<template>
    <div>
       <loading-overlay :active="apiStateLoading" :is-full-page="true" loader="bars"/>
       <CModalExtended
           color="dark"
           :closeOnBackdrop="false"
           :show.sync="$store.state.ubicacion.modalEstado"
           :title="tituloModal"
       >
           <CRow>                                                      
               <CCol sm="12" lg="12">
                   <CSelect
                       :label="$t('label.country')"
                       addLabelClasses="required text-right"
                       v-model="$v.formEstado.IdPais.$model"
                       :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                       :options="optionsListPaises"
                       :invalid-feedback="errorMessage($v.formEstado.IdPais)"
                       :is-valid="hasError($v.formEstado.IdPais)"
                   >
                   </CSelect>
               </CCol>
           </CRow> 
           <CRow>
               <CCol sm="12" lg="12">
                   <CInput
                   :label="$t('label.name')"
                   addLabelClasses="required text-right"
                   v-uppercase
                   :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                   :placeholder="$t('label.stateName')"
                   v-model="$v.formEstado.NbEstado.$model"
                   :invalid-feedback="errorMessage($v.formEstado.NbEstado)"
                   :is-valid="hasError($v.formEstado.NbEstado)" 
                   >
                   </CInput>
               </CCol>
           </CRow>                 
           <CRow>
               <CCol sm="12" lg="12">
                   <CSelect
                       :label="$t('label.status')"
                       :is-valid="formEstado.FgActEstado"
                       v-if="!idIndentification"
                       :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                       addLabelClasses='text-right'
                       v-model="formEstado.FgActEstado"
                       :value.sync="formEstado.FgActEstado"
                       :options="selectOptions"
                   />
               </CCol>
           </CRow>
           <CElementCover 
                :opacity="0.8"
               v-if="loading"
           >
               <label class="d-inline">{{$t('label.load')}}... </label>
               <CSpinner size="sm" />
           </CElementCover>
           <div slot="footer">
               <CButton
                   color="add"
                   class="m-1 ml-2"
                   @click="registerData"
                   outline 
                   :disabled="isSubmit"
                   >
                   <div>
                       <CIcon name="checkAlt"/>&nbsp;
                       {{$t('button.accept')}}
                   </div>
               </CButton>
               <CButton
                   color="wipe"
                   @click="cerrarModal"
                   class="m-1"
                   :disabled="apiStateFormLoading || loading "
               >
               <CIcon name="x"/>&nbsp;
                   {{$t('button.cancel')}}
               </CButton>
               
           </div>
       </CModalExtended>
   </div>
</template>
<script>
   import ENUM from '@/_store/enum';
   import { FormEstado } from '@/_validations/ubicacion/UbicacionValidation';
   import { mapState,mapMutations } from 'vuex';
   import UpperCase  from '@/_validations/uppercase-directive';
   import General from "@/_mixins/general";
   import ModalMixin from "@/_mixins/modal";
   //data
   function data() {
       return {
           srcError:'/img/errorimage.jpg',
           formEstado:{
               idEstado:0,
               NbEstado:'',
               IdPais:'',
               FgActEstado:true,
           },
           originalAct: '',            
           loading:false,
           tituloModal:'',
           isSubmit:false,
           apiStateLoading:false,
       }
   }

   //methods
   function cambio(e){
       this.formEstado.FgActEstado = !this.formEstado.FgActEstado;
   }
   function cerrarModal(){
       if(this.apiStateFormLoading || this.loading)
           return
       this.$store.commit('ubicacion/mutationModalEstado', false);
   }
   function registerData(){
       try {
           this.isSubmit=true;
           this.apiStateLoading=true;
           this.$v.$touch();
           if (this.$v.$error){
               throw this.$t('label.errorsPleaseCheck');
           }
           let { idEstado,NbEstado,IdPais,FgActEstado } = this.formEstado;
           const { dispatch } = this.$store;
           if(this.originalAct !== '' && idEstado !== 0){
               if(this.originalAct !== FgActEstado){   
                       this.$swal
                       .fire(this.alertProperties({
                           text: `${this.$t('label.changeStatusQuestion')+' '} ${this.NbEstado}?`,
                       }))
                       .then((result) => {
                       if (result.isConfirmed) {
                           dispatch('ubicacion/postregisterEstado', { idEstado,NbEstado,IdPais,FgActEstado });
                       }else {
                           // this.$swal('Operación cancelada');
                       }
                       this.isSubmit=false;
                       this.apiStateLoading=false;
                   })
               }else{
                    this.isSubmit=false;
                    this.apiStateLoading=false;
                    dispatch('ubicacion/postregisterEstado', { idEstado,NbEstado,IdPais,FgActEstado });
                }
           }else{
               this.isSubmit=false;
               this.apiStateLoading=false;
               dispatch('ubicacion/postregisterEstado', { idEstado,NbEstado,IdPais,FgActEstado });
           }
       } catch (error) {
           this.isSubmit=false;
           this.apiStateLoading=false;
           this.notifyError({text: error});
       }
   }
   function setAltImg(event){
       event.target.src = this.srcError;
   }

   //computed
   function idIndentification(){
       return this.formEstado.idEstado === 0;
   }
   function apiStateFormLoading() {
       let carga = false;
       if(this.apiStateForm === ENUM.LOADING){
           carga = !carga;
       }
       return carga;
   }
   function optionsListPaises(){
       if(this.myDataPais.length === 0){
           return [{
               value: '', 
               label: this.$t('label.select')
           }];
       }else{
           const chart = [{
               value: '', 
               label: this.$t('label.select')
           }];
           this.myDataPais.map(function(e){
               if(e.FgActCountry){
                   chart.push({
                       value: e.CountryId, 
                       label: e.CountryName,
                   })
               }
           })
           return chart;
       }
   }
   function IdEstadoSelected(){
       return this.formEstado.IdPais !== "";
   }
   function validURL(str) {
       var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
       '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
       '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
       '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
       '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
       '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
       return !!pattern.test(str);
   }
   function SvgIcon(){
       if(this.formEstado.IdPais !== ''){
           if(this.myDataPais.length !== 0){
               
               const x  = this.myDataPais.map(function(e){
                   return e.CountryId;
               }).indexOf(this.formEstado.IdPais);
               if(x !== -1){
                   if(this.myDataPais[x].Icon === null || this.myDataPais[x].Icon === '' || this.myDataPais[x].Icon === undefined){
                       return this.srcError;
                   }   
                   return this.validURL(this.myDataPais[x].Icon) ? this.myDataPais[x].Icon : `${this.$store.getters["connection/getBase"]}${this.myDataPais[x].Icon.replace('public/','', null, 'i')}`;
               }else{
                   return this.srcError;
               }
           }
       }
       return this.srcError;
   }
   
   function selectOptions(){
       
   return [
               { 
                   value: true, 
                   label: this.$t('label.ACTIVO')
               },
               { 
                   value: false, 
                   label: this.$t('label.INACTIVO')
               }
           ]
   }

   //watch
   function modalEstado(newQuestion,Oldquestion){
       if(newQuestion === false){
           this.formEstado.idEstado=0;
           this.formEstado.NbEstado='';
           this.formEstado.IdPais='';
           this.originalAct = '';
           this.formEstado.FgActEstado=true;
           this.tituloModal = '';
           this.isSubmit=false;
           this.apiStateLoading=false;
           this.$nextTick(() => { this.$v.$reset() })
           this.$store.commit('ubicacion/asignarid', 0);
       }else{
           if(this.idState === 0){
               this.formEstado.IdPais = this.filtroPais;
               this.tituloModal = this.$t('label.nuevo')+this.$t('label.state');
           }
       }
   }
   function idState(newQuestion,Oldquestion){
       if(this.modalEstado){
           if(newQuestion !== 0){
               //this.$store.state.ubicacion.apiStateForm = ENUM.LOADING;
               this.apiStateLoading=true;
               this.$http.ejecutar('GET', 'State-by-id',{ StateId: newQuestion }, '' ).then(response => {
                   if(response.status === 200){
                       const estadoInformation = response.data.data;
                       if(estadoInformation.length !== 0){
                           this.tituloModal = this.$t('label.edit')+this.$t('label.state')+": "+estadoInformation[0].StateName;
                           this.formEstado.idEstado = estadoInformation[0].StateId;
                           this.formEstado.IdPais = estadoInformation[0].CountryId;
                           this.formEstado.NbEstado = estadoInformation[0].StateName;
                           this.originalAct = estadoInformation[0].FgActState;
                           this.formEstado.FgActEstado = estadoInformation[0].FgActState;
                           //this.$store.state.ubicacion.apiStateForm = ENUM.INIT;
                           this.apiStateLoading=false;
                           this.$v.$touch();
                       }else{
                           this.apiStateLoading=false;
                           //this.$store.state.ubicacion.apiStateForm = ENUM.ERROR;
                           this.$store.commit('ubicacion/mutationModalEstado', false);
                       }
                   }else{
                       this.apiStateLoading=false;
                       //this.$store.state.ubicacion.apiStateForm = ENUM.ERROR;
                       this.$store.commit('ubicacion/mutationModalEstado', false);
                   }
               }).catch(err => {
                   this.apiStateLoading=false;
                   //this.$store.state.ubicacion.apiStateForm = ENUM.ERROR;
                   this.$store.commit('ubicacion/mutationModalEstado', false);
               });
           }
       }
   }

   export default {
       name: 'app-modal-estado',
       data,
       mixins: [General,ModalMixin],
       validations(){ return FormEstado() },
       methods:{
           registerData,
           cambio,
           validURL,
           setAltImg,
           cerrarModal
       },
       directives: UpperCase,
       computed:{
           idIndentification,
           optionsListPaises,
           IdEstadoSelected,
           SvgIcon,
           selectOptions,
           apiStateFormLoading,
           //tituloModal,
           ...mapState({
               idState: state=> state.ubicacion.id,
               modalEstado: state=> state.ubicacion.modalEstado,
               apiState: state => state.ubicacion.apiState,
               filtroPais: state => state.ubicacion.filtroPais,
               apiStateForm: state => state.ubicacion.apiStateForm,
               myDataPais: state => state.ubicacion.myDataPais
           })
       },
       watch:{
           idState,
           modalEstado
       }
   }
</script>